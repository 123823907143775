const tagmanager = {
    init: () => {
        tagmanager.headerMenu();
        tagmanager.footerMenu();
        tagmanager.socials();
        tagmanager.management();
        tagmanager.navBarMenu();
        tagmanager.game();
    },
    headerMenu: () => {
        document.querySelectorAll('header nav ul li a').forEach((el) => {
            el.addEventListener('click', function () {
                dataLayer.push({
                    event: 'custom_action',
                    event_name: 'header_menu_click',
                    param_1_name: 'menu_item',
                    param_1_value: el.innerText,
                });
            });
            
        });
    },
    footerMenu: () => {
        document.querySelectorAll('footer nav.menu ul li a').forEach((el) => {
            el.addEventListener('click', function () {
                dataLayer.push({
                    event: 'custom_action',
                    event_name: 'footer_menu_click',
                    param_1_name: 'menu_item',
                    param_1_value: el.innerText,
                });
            });
            
        });
    },
    game: () => {
        document.querySelectorAll('#page-404 .btn').forEach((el) => {
            el.addEventListener('click', function () {
                dataLayer.push({
                    event: 'custom_action',
                    event_name: '404_button_click',
                    param_1_name: 'button_name',
                    param_1_value: el.innerText,
                });
            });        
        });
        document.querySelectorAll('#page-404 .btn-link.easter-egg-random').forEach((el) => {
            el.addEventListener('click', function () {
                dataLayer.push({
                    event: 'custom_action',
                    event_name: '404_easter_egg_click',
                    param_1_name: 'button_name',
                    param_1_value: 'generálás',
                });
            });        
        });
        document.querySelectorAll('#page-404 .btn-link.redo').forEach((el) => {
            el.addEventListener('click', function () {
                dataLayer.push({
                    event: 'custom_action',
                    event_name: '404_easter_egg_click',
                    param_1_name: 'button_name',
                    param_1_value: 'újragenerálás',
                });
            });        
        });
    },
    socials: () => {
        document.querySelectorAll('.hero .social a').forEach((el) => {
            el.addEventListener('click', function () {
                dataLayer.push({
                    event: 'custom_action',
                    event_name: 'footer_menu_click',
                    param_1_name: 'menu_item',
                    param_1_value: el.getAttribute('title'),
                });
            });
            
        });
    },
    management: () => {
        document.querySelectorAll('.management .small .images .image img').forEach((el) => {
            el.addEventListener('click', function () {
                dataLayer.push({
                    event: 'custom_action',
                    event_name: 'management_click',
                    param_1_name: 'menu_item',
                    param_1_value: el.getAttribute('alt'),
                });
            });
            
        });
    },
    navBarMenu: () => {
        document.querySelectorAll('.nav-bar .points li').forEach((el) => {
            el.addEventListener('click', function () {
                dataLayer.push({
                    event: 'custom_action',
                    event_name: 'navbar_point_click',
                    param_1_name: 'menu_item',
                    param_1_value: el.querySelector('button').innerText,
                });
            });
        });
        document.querySelectorAll('.nav-bar .points li button').forEach((el) => {
            el.addEventListener('click', function () {
                dataLayer.push({
                    event: 'custom_action',
                    event_name: 'navbar_menu_click',
                    param_1_name: 'menu_item',
                    param_1_value: el.innerText,
                });
            });
        });
    }
}

export default tagmanager;