import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    init: () => {
        const form = document.querySelector('#contact-form');

        form.querySelectorAll('input, textarea').forEach((input) => {
             input.addEventListener('focus', () => {
                input.parentElement.classList.remove('has-error');
                input.parentElement.querySelectorAll('.error-message').forEach((error) => {
                    error.style.display = 'none';
                });
            });
        });

        form.querySelector('#firstname').addEventListener('blur', () => {
            contact.v_firstname();
        });

        form.querySelector('#lastname').addEventListener('blur', () => {
            contact.v_lastname();
        });

        form.querySelector('#phone').addEventListener('blur', () => {
            contact.v_phone();
        });

        form.querySelector('#email').addEventListener('blur', () => {
            contact.v_email();
        });

        form.querySelector('#message').addEventListener('blur', () => {
            contact.v_message();
        });

        form.querySelector('#gdpr').addEventListener('change', () => {
            contact.v_gdpr();
        });

    },
    send: (token) => {
        const form = document.querySelector('#contact-form');
        
        if (token == undefined) {
            return;
        }
        
        if (!contact.validate()) {
            return;
        }
        
        setTimeout(() => {
            form.querySelector('.btn').setAttribute('disabled', 'disabled');
            form.querySelector('.btn svg').classList.add('show');
            form.querySelector('.btn span').innerText = form.querySelector('.btn span').dataset.sending;
        }, 100);

        const data = {
            firstname: form.querySelector('#firstname').value,
            lastname: form.querySelector('#lastname').value,
            phone: form.querySelector('#phone').value,
            email: form.querySelector('#email').value,
            message: form.querySelector('#message').value,
            gdpr: form.querySelector('#gdpr').checked,
            token: token
        };

        axios.post('/contact-save', data)
            .then((response) => {
                if (response.data.status == 'success') {
                    Swal.fire({
                        title: "Sikeres üzenetküldés!",
                        text: "Köszönjük megkeresését, hamarason felveszük önnel a kapcsolatot!",
                        icon: "success",
                        showCloseButton: false,
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    });

                    dataLayer.push({
                        event: 'custom_action',
                        event_name: 'contact_form_submit'
                    });
                    
                    form.querySelectorAll('input:not([type="checkbox"]), textarea').forEach((input) => {
                        input.value = '';
                    });

                    setTimeout(() => {
                        form.querySelector('.btn').removeAttribute('disabled');
                        form.querySelector('.btn svg').classList.remove('show');
                        form.querySelector('.btn span').innerText = form.querySelector('.btn span').dataset.title;
                    }, 1000);
                } else {
                    Swal.fire({
                        title: "Opps!",
                        text: "Hiba történt az üzenetküldés során, kérjük próbálja újra később!",
                        icon: "error",
                        showCloseButton: false,
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    });
                    setTimeout(() => {
                        form.querySelector('.btn').removeAttribute('disabled');
                        form.querySelector('.btn svg').classList.remove('show');
                        form.querySelector('.btn span').innerText = form.querySelector('.btn span').dataset.title;
                    }, 1000);
                }
            })
            .catch((error) => {
                Swal.fire({
                    title: "Opps!",
                    text: "Hiba történt az üzenetküldés során, kérjük próbálja újra később!",
                    icon: "error",
                    showCloseButton: false,
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                });
                setTimeout(() => {
                    form.querySelector('.btn').removeAttribute('disabled');
                    form.querySelector('.btn svg').classList.remove('show');
                    form.querySelector('.btn span').innerText = form.querySelector('.btn span').dataset.title;
                }, 1000);
            });
    },
    validate: () =>{
        let error = false;
        
        if (!contact.v_firstname()) {
            error = true;
        }

        if (!contact.v_lastname()) {
            error = true;
        }

        if (!contact.v_phone()) {
            error = true;
        }

        if (!contact.v_email()) {
            error = true;
        }

        if (!contact.v_message()) {
            error = true;
        }

        if (!contact.v_gdpr()) {
            error = true;
        }

        return !error;
    },
    v_firstname: () => {
        const form = document.querySelector('#contact-form');
        if (form.querySelector('#firstname').value.trim() == '' && form.querySelector('#firstname').value.trim().length < 3) {
            form.querySelector('#firstname').parentElement.classList.add('has-error');
            form.querySelector('#firstname-required').style.display = 'block';
            return false;
        } else {
            form.querySelector('#firstname').parentElement.classList.remove('has-error');
            form.querySelector('#firstname-required').style.display = 'none';
            return true;
        }
    },

    v_lastname: () => {
        const form = document.querySelector('#contact-form');
        if (form.querySelector('#lastname').value.trim() == '' && form.querySelector('#lastname').value.trim().length < 3) {
            form.querySelector('#lastname').parentElement.classList.add('has-error');
            form.querySelector('#lastname-required').style.display = 'block';
            return false;
        } else {
            form.querySelector('#lastname').parentElement.classList.remove('has-error');
            form.querySelector('#lastname-required').style.display = 'none';
            return true;
        }
    },
    v_phone: () => {
        const form = document.querySelector('#contact-form');
        if (form.querySelector('#phone').value.trim() == '' && form.querySelector('#phone').value.trim().length < 8) {
            form.querySelector('#phone').parentElement.classList.add('has-error');
            form.querySelector('#phone-required').style.display = 'block';
            return false;
        } else {
            form.querySelector('#phone').parentElement.classList.remove('has-error');
            form.querySelector('#phone-required').style.display = 'none';
            return true;
        }
    },
    v_email: () => {
        const form = document.querySelector('#contact-form');
        if (form.querySelector('#email').value.trim() == '') {
            form.querySelector('#email').parentElement.classList.add('has-error');
            form.querySelector('#email-required').style.display = 'block';
            return false;
        } else if (!contact.isValidEmail(form.querySelector('#email').value.trim())) {
            form.querySelector('#email').parentElement.classList.add('has-error');
            form.querySelector('#email-wrong').style.display = 'block';
            return false;
        } else {
            form.querySelector('#email').parentElement.classList.remove('has-error');
            form.querySelector('#email-required').style.display = 'none';
            form.querySelector('#email-wrong').style.display = 'none';
            return true;
        }
    },
    isValidEmail: (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    v_message: () => {
        const form = document.querySelector('#contact-form');
        if (form.querySelector('#message').value.trim() == '' && form.querySelector('#message').value.trim().length < 10) {
            form.querySelector('#message').parentElement.classList.add('has-error');
            form.querySelector('#message-required').style.display = 'block';
            return false;
        } else {
            form.querySelector('#message').parentElement.classList.remove('has-error');
            form.querySelector('#message-required').style.display = 'none';
            return true;
        }
    },
    v_gdpr: () => {
        const form = document.querySelector('#contact-form');
        if (!form.querySelector('#gdpr').checked) {
            form.querySelector('#gdpr').parentElement.classList.add('has-error');
            form.querySelector('#gdpr-required').style.display = 'block';
            return false;
        } else {
            form.querySelector('#gdpr').parentElement.classList.remove('has-error');
            form.querySelector('#gdpr-required').style.display = 'none';
            return true;
        }
    },
};