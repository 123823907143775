export default function () {
    let key = [78, 89, 65, 78, 67, 65, 84]
  // let key = [67]
  let ck = 0
  let max = key.length

  let nyancat = function () {
    dataLayer.push({
      event: 'custom_action',
      event_name: 'easteregg_fire',
      param_1_name: 'esteregg_name',
      param_1_value: 'nyancat',
    });
    var shock = document.createElement('div')
    var img = new Image()
    img.src = data
    img.style.width = '230px'
    img.style.height = '150px'
    img.style.transition = '6s all linear'
    img.style.position = 'fixed'
    img.style.left = '-400px'
    img.style.bottom = 'calc(-50% + 340px)'
    img.style.zIndex = 999999

    document.body.appendChild(img)

    // window.setTimeout(function(){
    //   img.style.left = 'calc(50% - 200px)'
    // },50)

    window.setTimeout(function () {
      img.style.left = 'calc(100% + 500px)'
    }, 50)

    window.setTimeout(function () {
      img.parentNode.removeChild(img)
    }, 6000)

  }

  let record = function (e) {

    if (e.which === key[ck]) {
      ck++
    } else {
      ck = 0
    }

    if (ck >= max) {
      nyancat()
      ck = 0
    }

  }

  let init = function (data) {
    document.addEventListener('keyup', record)
  }

  let data = 'https://i.imgur.com/3kI22fU.gif'

    init(data)
    
    window.estereggs.nyancat = nyancat;

}