export default function () {
    let key = [75, 85, 84, 89, 65]
  let ck = 0
  let max = key.length

  let doggy = function () {
    dataLayer.push({
      event: 'custom_action',
      event_name: 'easteregg_fire',
      param_1_name: 'esteregg_name',
      param_1_value: 'doggy',
    });
    var shock = document.createElement('div')
    var img = new Image()
    img.src = data
    img.style.width = '450px'
    img.style.height = '350px'
    img.style.transition = '4s all linear'
    img.style.position = 'fixed'
    img.style.left = '-400px'
    img.style.bottom = '10px'
    img.style.zIndex = 999999

    document.body.appendChild(img)

    // window.setTimeout(function(){
    //   img.style.left = 'calc(50% - 200px)'
    // },50)

    window.setTimeout(function () {
      img.style.left = 'calc(100% + 500px)'
    }, 50)

    window.setTimeout(function () {
      img.parentNode.removeChild(img)
    }, 6000)

  }

  let record = function (e) {

    if (e.which === key[ck]) {
      ck++
    } else {
      ck = 0
    }

    if (ck >= max) {
      doggy()
      ck = 0
    }

  }

  let init = function (data) {
    document.addEventListener('keyup', record)
  }

  let data = 'https://i.imgur.com/PwxV3zM.gif'

    init(data)
    
    window.estereggs.doggy = doggy;

}