export default function () {
    var key = [66, 82, 73, 84, 78, 69, 89];
  var ck = 0;
  var max = key.length;

  var britney = function () {
    dataLayer.push({
      event: 'custom_action',
      event_name: 'easteregg_fire',
      param_1_name: 'esteregg_name',
      param_1_value: 'britney',
    });
    var shock = document.createElement('div');
    var img = new Image;
    img.src = data;
    img.style.width = '250px';
    img.style.height = '180px';
    img.style.transition = '1s all';
    img.style.position = 'fixed';
    img.style.left = 'calc(50% - 125px)';
    img.style.bottom = '-149px';
    img.style.zIndex = 999999;

    document.body.appendChild(img);

    window.setTimeout(function () {
      img.style.bottom = '0px';
    }, 50);

    window.setTimeout(function () {
      img.style.bottom = '-149px';
    }, 4300);
    window.setTimeout(function () {
      img.parentNode.removeChild(img);
      shock.parentNode.removeChild(shock);
    }, 5400);

  };

  var record = function (e) {

    if (e.which === key[ck]) {
      ck++;
    } else {
      ck = 0;
    }

    if (ck >= max) {
      britney();
      ck = 0;
    }

  };

  var init = function (data) {

    document.addEventListener('keyup', record);

  };

  var data = 'https://i.imgur.com/EuhEYw8.gif'

    init(data);
    
    window.estereggs.britney = britney;

}